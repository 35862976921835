@import url('https://fonts.googleapis.com/css?family=Open+Sans');
@import "~react-image-gallery/styles/css/image-gallery.css";
.main-header-font{
    font-family: "'Playfair Display', Georgia, serif";
    color: #1b1b1b;
}
.main-back-ground{
    background-color: rgb(245, 243, 243);
}
.main-paragraph-font{
    font-family: 'Open Sans', arial, sans-serif;    
    color: #5e5e5e;
    font-size: 18px;
}
.header-font{
    font-family: 'Open Sans', arial, sans-serif;    
    color: #1b1b1b;
}
.sub-header-font{
    font-family: 'Open Sans', arial, sans-serif;    
    color: #8c6f6f;
}
.sub-header-font2{
    font-family: "'Playfair Display', Georgia, serif";
    color: #1b1b1b;
}
.sub-header-dark{
    font-family: 'Open Sans', arial, sans-serif; 
    color: #1b1b1b;
}
.paragraph-font{
    font-family: 'Open Sans', arial, sans-serif;    
    color: #5e5e5e;
}

.faq-summary{
    font-family: 'Open Sans', arial, sans-serif;   
    font-size: 22px;
    color: #1b1b1b;
}
.note-text{
    font-family: 'Open Sans', arial, sans-serif;    
    color: #5e5e5e;
    font-size: 12px;
}
.button-grey{
    background-color: #e5e2e2;
    font-family: 'Open Sans', arial, sans-serif;   
    font-size: 14px;
    border: none;
    font-weight: bold;
    letter-spacing: 3px;
    padding: 17px 35px 17px 35px;
}
.button-grey:hover{
    filter: brightness(96%);
}
@media (max-width: 576px){
    .button-grey.wide{
        padding: 17px 80px 17px 80px;
    }
}
.link{
    font-family: 'Open Sans', arial, sans-serif;    
    color: #8c6f6f;
}
/* To increase spacing between letters */
.wide-text{
    letter-spacing: .2rem;
}
details > summary {
    list-style: none;
}
details > summary::-webkit-details-marker {
    display: none;
}

.justify-text{
    text-align: justify;
}
.faq-hover:hover{
    color: #8c6f6f;
}
/*
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
*/